nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px 20px 50px 30px;
}

.nav-title {
  color: var(--primary);
  font-size: 16px;
  font-weight: 900;
}

.nav-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}