@import 'colors';

html {
  --background: #{$background-light};
  --primary: #{$primary-light};
  --secondary: #{$secondary-light};
}

html.dark {
  --background: #{$background-dark};
  --primary: #{$primary-dark};
  --secondary: #{$secondary-dark};
}

// React's "root"
#root {
  width: 100%;
  height: 100vh;
}

:root {
  font-family: $font-main;
  color: var(--primary);
  background-color: var(--background);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// To avoid the hover color to stay after pressing the button on mobile
@media(hover: hover) {
  button:hover {
    color: var(--primary);
  }

  a:hover {
    color: var(--primary);
  }
}

body {
  margin: 0;
  width: 100vw;
  height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

h1 {
  font-size: 64px;
  font-weight: bold;
}

p {
  margin: 0;
}

a {
  color: var(--secondary);
  transition: color 0.2s;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

a:active {
  color: var(--secondary);
}
