.wrapper {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  row-gap: 35px;
}

.hero {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.hero-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 55px;
  padding-top: 30px;
}