@import '../../styles';

.toggle-btn {
  color: var(--secondary);
  background: transparent;
  transition: 0.2s;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.toggle-btn:active {
  color: var(--primary);
}
