footer {
  padding: 50px 60px 50px 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  color: var(--secondary);
  font-size: 10px;
}

.footer-copyright {
  display: inline;
  font-weight: bold;
}

.footer-links {
  display: flex;
  justify-content: center;
  align-items: center;
}